<template>
	<div id="nationality">
		<div class="input-container">
			{{ languageStrings.nationality }}
			<div id="country-dropdown-container">
				<CountryDropdownMenu
					v-if="countryList?.length > 0"
					:systemSettings="systemSettings"
					:playerState="playerState"
					:countryList="countryList"
					:languageStrings="languageStrings"
					:countryDropdownType="`Nationality`"
				/>
			</div>
			<div class="encore-style-button-container">
				<button class="btn curved-border encore-style-button" :title="languageStrings.continueText" @click="submitToParent()">{{ languageStrings.continueText }}</button>
			</div>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import CountryDropdownMenu from "@/components/CountryDropdownMenu";

export default {
	name: "Nationality",
	props: {
		systemSettings: Object,
		playerState: Object,
		countryList: Array,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	components: {
		CountryDropdownMenu,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			nationality: "",
			newAccountProperty: {
				propertyName: "",
				propertyValue: "",
				componentName: "Nationality",
			},
		};
	},
	watch: {},
	created() {
		this.eventBus.on("countrySelected", (payload) => {
			this.nationality = payload.name;
			this.newAccountProperty.propertyName = "nationality";
			this.newAccountProperty.propertyValue = payload.countryCode;
			this.eventBus.emit("addNewAccountProperty", this.newAccountProperty);
		});
		onBeforeUnmount(() => {
			this.eventBus.off("countrySelected");
		});
	},
	mounted() {},
	methods: {
		submitToParent() {
			if (!this.nationality) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.nationalityIsRequired;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			// property set by dropdown component @ created() --> this.eventBus.on("countrySelected")
			// so we just advance the component here
			this.eventBus.emit("advanceComponent");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-container {
	display: flex;
	flex-direction: column;
	margin: 15px auto;
	padding: 15px;
	max-width: 30em;
}

input {
	font-size: 1.5em;
	border-radius: 12px;
}

.encore-style-button-container {
	margin-top: 2vh;
}

/* .btn {
	height: 4.5vh;
} */
</style>
